import { OutlineButton, SubmitButton } from '@netspresso/components';
import { AxiosError } from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCompressFormContext, useLoaderContext, useModalContext } from '../../../../../../context';
import { LoaderActions, ModalActions } from '../../../../../../reducers';
import { CompressModelType } from '../../../../../../schemes';
import { CompressionResult } from '../../../../components';

type CompletedButtonGroupsProps = {
  modelUid: string;
  handleCreateCompression: (data: CompressModelType) => void;
  handleCompressionError: (error: AxiosError) => void;
  onCloseModal: React.MouseEventHandler<HTMLButtonElement>;
  onSubmitResult: React.MouseEventHandler<HTMLButtonElement>;
};

export const CompletedButtonGroups: React.FC<CompletedButtonGroupsProps> = ({
  modelUid,
  handleCreateCompression,
  handleCompressionError,
  onCloseModal,
  onSubmitResult,
}) => {
  const navigate = useNavigate();
  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();
  const {
    compressForm: { reset, getValues },
  } = useCompressFormContext();

  const handleClickReset: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    reset();
    navigate(`/compression`);
  };

  const handleClickModify: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    dispatchLoading({ type: LoaderActions.Show });

    try {
      const data = getValues();

      await handleCreateCompression(data);
    } catch (error) {
      handleCompressionError(error as AxiosError);
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const handleClickShowResult: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    dispatchModal({
      type: ModalActions.Show,
      payload: <CompressionResult onClose={onCloseModal} onSubmit={onSubmitResult} />,
    });
  };

  return (
    <>
      <OutlineButton className="mr-3" onClick={handleClickReset}>
        Reset
      </OutlineButton>
      <OutlineButton className="mr-3" onClick={handleClickModify}>
        Modify
      </OutlineButton>
      <SubmitButton className="mr-3" type="button" onClick={handleClickShowResult}>
        Show Result
      </SubmitButton>
    </>
  );
};
