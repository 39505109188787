import { HeaderText } from '@netspresso/components';
import { ADVANCED_COMPRESSION, NotificationMessages } from '@netspresso/shared';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { SubmitErrorHandler } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dialog, LEVEL_WARNING, ModelErrorModal, Toast, useNotificationContext } from '../../../../components';
import { COMPRESSED_MODEL, COMPRESSION, COMPRESSION_ID, COMPRESSION_TYPE, LAYERS } from '../../../../constants';
import { useAuthContext, useCompressFormContext, useLoaderContext, useModalContext } from '../../../../context';
import { NetworkLayerContextProvider } from '../../../../context/NetworkLayerContext';
import { CURRENT_STEPS, CurrentSteps } from '../../../../lib';
import { LoaderActions, ModalActions } from '../../../../reducers';
import { CompressModelType } from '../../../../schemes';
import { CompressService } from '../../../../services';
import { CompressionResult, CompressionSteps } from '../../components';
import { ButtonGroups, CompletedButtonGroups } from './components';
import { SelectMethod } from './steps/SelectMethod/SelectMethod';
import { SetCompression } from './steps/SetCompression/SetCompression';

export const AdvancedCompression: React.FC = () => {
  const navigate = useNavigate();
  const { modelUid } = useParams();
  const { pathname } = useLocation();

  const {
    compressForm: { setValue, handleSubmit, reset, getValues },
  } = useCompressFormContext();
  const [currentStep, setCurrentStep] = useState<CurrentSteps>(CURRENT_STEPS.FIRST);
  const [, dispatchModal] = useModalContext();
  const [, dispatchLoading] = useLoaderContext();
  const { showToast, hideToast, onClickToastHandler } = useNotificationContext();

  // 테스트용
  // const { user, login, refetchUserData } = useAuthContext();

  // useEffect(() => {
  //   login('compressor_admin@nota.ai', 'CompressorAdmin12!');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const resetSecondStep = useCallback(() => {
    setValue(COMPRESSION, null);
    setValue(COMPRESSION_ID, '');
    setValue(LAYERS, [], { shouldValidate: true });
  }, [setValue]);

  const handleClickFirstStep = () => {
    resetSecondStep();
    setCurrentStep(CURRENT_STEPS.FIRST);
    navigate('/compression');
  };

  useEffect(() => {
    return () => {
      reset();
      dispatchLoading({ type: LoaderActions.Hide });
      dispatchModal({ type: ModalActions.Hide });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(COMPRESSION_TYPE, ADVANCED_COMPRESSION, { shouldValidate: true });
  }, [setValue]);

  useEffect(() => {
    if (pathname.includes('config') && getValues(COMPRESSION)) {
      setCurrentStep(CURRENT_STEPS.SECOND);

      return;
    }

    navigate(`/compression`);
    setCurrentStep(CURRENT_STEPS.FIRST);
    resetSecondStep();
  }, [modelUid, pathname, getValues, setValue, navigate, resetSecondStep]);

  const handleClickBack = useCallback(() => {
    if (currentStep === CURRENT_STEPS.FIRST) {
      navigate(`/compression`);

      return;
    }
    // secondStep
    resetSecondStep();
    navigate(`/compression`);
    setCurrentStep(CURRENT_STEPS.FIRST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelUid, resetSecondStep, currentStep]);

  const onCloseModal: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  const onSubmitResult: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    // modal retrain 핸들러
    event.stopPropagation();

    dispatchModal({ type: ModalActions.Hide });
  };

  const handleCompressionError = (error: AxiosError) => {
    dispatchModal({
      type: ModalActions.Show,
      payload: (
        <ModelErrorModal
          errorTitle="Unable to compress"
          errorMessage={error.response?.data.message}
          errorLog={error.response?.data.data.error_log}
          modelId={error.response?.data.data.model_id}
          onSubmit={() => {
            dispatchModal({ type: ModalActions.Hide });
          }}
        />
      ),
    });
  };

  // advanced compression confirm handler
  const handleConfirmStart = async (data: CompressModelType) => {
    try {
      dispatchLoading({ type: LoaderActions.Show });
      dispatchModal({ type: ModalActions.Hide });

      const compressResult = await CompressService.startCompression(data);
      const { data: compressedModel } = await CompressService.getModel({
        model_id: compressResult.data.new_model_id,
      });

      setValue(COMPRESSED_MODEL, compressedModel);

      setValue(COMPRESSION, compressResult.data);
      dispatchLoading({ type: LoaderActions.Hide });
      // refetchUserData();
      setCurrentStep(CURRENT_STEPS.COMPLETED);
      dispatchModal({
        type: ModalActions.Show,
        payload: <CompressionResult onClose={onCloseModal} onSubmit={onSubmitResult} />,
      });
    } catch (error) {
      handleCompressionError(error as AxiosError);
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const handleCancelStart: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    dispatchModal({ type: ModalActions.Hide });
  };

  // advanced compression first step submit handler
  const handleCreateCompression = async (data: CompressModelType) => {
    const promises = [CompressService.createCompression(data)];

    const [compressionResponse] = await Promise.all(promises);

    resetSecondStep();

    setValue(COMPRESSION, compressionResponse.data);
    setValue(COMPRESSION_ID, compressionResponse.data.compression_id);
    setCurrentStep(CURRENT_STEPS.SECOND);
    setValue(LAYERS, compressionResponse.data.available_layers, { shouldValidate: true });
  };

  const onSubmit = async (data: CompressModelType) => {
    dispatchLoading({ type: LoaderActions.Show });

    try {
      if (currentStep === CURRENT_STEPS.FIRST) {
        await handleCreateCompression(data);
        navigate(`/compression/config`);
        dispatchLoading({ type: LoaderActions.Hide });

        return;
      }

      // second step
      // if (user.credit >= 50) {
      //   dispatchModal({
      //     type: ModalActions.Show,
      //     payload: (
      //       <Dialog
      //         title={NotificationMessages.compressConfirmMessage}
      //         // infoText={NotificationMessages.startProjectMessage(50)}
      //         infoText=""
      //         onClickConfirm={() => handleConfirmStart(data)}
      //         onClickCancel={handleCancelStart}
      //       />
      //     ),
      //   });
      // } else {
      //   showToast(
      //     <Toast
      //       content={NotificationMessages.notEnoughCredit}
      //       level={LEVEL_WARNING}
      //       onClick={onClickToastHandler}
      //       hideToast={hideToast}
      //     />
      //   );
      // }
    } catch (error) {
      handleCompressionError(error as AxiosError);
    } finally {
      dispatchLoading({ type: LoaderActions.Hide });
    }
  };

  const onError: SubmitErrorHandler<CompressModelType> = (formErrors) => {
    showToast(
      <Toast
        content="Please review form fields."
        level={LEVEL_WARNING}
        onClick={onClickToastHandler}
        hideToast={hideToast}
      />
    );
  };

  return (
    <div className="w-full relative px-20">
      <div className="flex justify-between">
        <HeaderText className="mb-2 pl-1 self-end" type="main">
          NetsPresso Compressor
        </HeaderText>
        <CompressionSteps currentStep={currentStep} onClickFirstStep={handleClickFirstStep} />
      </div>
      <p className="text-lg text-black py-6">
        <span className="font-bold">Explore NetsPresso Compressor</span>, featuring an AI model visualizer and
        layer-based compression. Try it with sample models like <span className="font-bold">YOLOv4 and MobileNet</span>.
        <br />
        To apply it to your own AI models, use the <span className="font-bold">NetsPresso Python Package</span>. Learn
        more in the{' '}
        <a
          className="font-bold cursor-pointer underline"
          href="http://docs.netspresso.ai/docs/python_package"
          target="_blank"
          rel="noreferrer"
        >
          Documentation
        </a>{' '}
        and{' '}
        <a
          className="font-bold cursor-pointer underline"
          href="https://github.com/Nota-NetsPresso/PyNetsPresso/blob/develop/notebooks/NetsPresso_Tutorial(with%20Compressor).ipynb"
          target="_blank"
          rel="noreferrer"
        >
          Tutorial
        </a>
        .
      </p>
      <section className="bg-white rounded-lg shadow mb-2 px-6 pt-3 pb-7">
        <form className="w-full" onSubmit={handleSubmit(onSubmit, onError)}>
          {currentStep === CURRENT_STEPS.FIRST ? (
            <SelectMethod />
          ) : (
            <NetworkLayerContextProvider>
              <SetCompression currentStep={currentStep} />
            </NetworkLayerContextProvider>
          )}
          <div className="flex justify-end pt-6 mt-4">
            <div>
              {currentStep === CURRENT_STEPS.COMPLETED ? (
                <CompletedButtonGroups
                  modelUid={modelUid || ''}
                  handleCreateCompression={handleCreateCompression}
                  handleCompressionError={handleCompressionError}
                  onCloseModal={onCloseModal}
                  onSubmitResult={onSubmitResult}
                />
              ) : (
                <ButtonGroups currentStep={currentStep} handleClickBack={handleClickBack} />
              )}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
