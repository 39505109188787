import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { useLoaderContext, useModalContext } from '../../../context';
import { Loading } from '../../Loading';
import { ModalContainer } from '../../Modal';
import { Container } from '../Container';
import { Content } from '../Content';
import { Header } from '../Header';
import { Main } from '../Main';

export const Layout: React.FC = () => {
  const [{ isLoading }] = useLoaderContext();
  const [{ showModal, children }] = useModalContext();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/compression');
    }
  }, [location, navigate]);

  return (
    // <AuthRequired>
    <Container>
      <Header />
      <Main>
        <ModalContainer showModal={showModal}>{children}</ModalContainer>
        <Loading show={isLoading} />
        <Content>
          <Outlet />
        </Content>
      </Main>
    </Container>
    // </AuthRequired>
  );
};
